// Slider.js
import React, { useState } from 'react';  // Ensure useState is imported here
import './Slider.css';
import logo from '../assets/images/logo.png';  // Adjust the path if necessary

const Slider = ({ isOpen }) => {
    const [giftLinkText, setGiftLinkText] = useState("BUY ME A GIFT");

    const handleMouseEnter = () => setGiftLinkText("TAP ME HARD");
    const handleMouseLeave = () => setGiftLinkText("BUY ME A GIFT");

    return (
        <div className={`slider ${isOpen ? 'open' : ''}`}>
            <div className="slider-logo-container">
            <img src={logo} alt="Logo" className="slider-logo wiggle-constant-logo" /> 
            </div>
            <ul className="nav-links">
                <li><a href="/aboutme">About Me</a></li>
                <li><a href="/contactme">Contact Me</a></li>
                <li><a href="/exclusive" className="wiggle-constant">SEXXX</a></li> 
                <li><a href="/sneakpeak">Sneak Peak</a></li>
                <li><a href="/sneakpeak">Shorties</a></li>
            </ul>
            <div className="gift-link">
                <a href="https://www.amazon.com/hz/wishlist/ls/YOUR_WISHLIST_ID"
                   onMouseEnter={handleMouseEnter}
                   onMouseLeave={handleMouseLeave}
                   target="_blank" rel="noopener noreferrer">
                   {giftLinkText}
                </a>
                <div className="gift-link2">
                <a href="https://www.amazon.com/hz/wishlist/ls/YOUR_WISHLIST_ID" target="_blank" rel="noopener noreferrer">BUY ME A GIFT</a>
            </div>
            <div className="gift-link">
                <a href="https://www.amazon.com/hz/wishlist/ls/YOUR_WISHLIST_ID" target="_blank" rel="noopener noreferrer">BUY ME A GIFT</a>
            </div>
            </div>
        </div>
    );
};

export default Slider;