// Header.js
import React, { useState } from 'react';
import './Header.css';
import logo from '../assets/images/logo.png';
import Slider from './Slider';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="header">
            <div className="logo-container">
                <img src={logo} alt="Logo" className="logo" />
            </div>
            <div className="burger" onClick={toggleMenu}>
                <div className="burger-lines">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <Slider isOpen={isMenuOpen} />
        </header>
    );
};

export default Header;