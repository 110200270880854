// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import './App.css';

// Importing Pages
import AboutMe from './pages/AboutMe';
import ContactMe from './pages/ContactMe';
import Exclusive from './pages/Exclusive';
import SneakPeak from './pages/SneakPeak';

const App = () => {
  return (
    <Router>
      <div className="app">
        {/* Header Component */}
        <Header />

        {/* Main Content Routes */}
        <Routes>
          <Route path="/aboutme" element={<AboutMe />} />
          <Route path="/contactme" element={<ContactMe />} />
          <Route path="/exclusive" element={<Exclusive />} />
          <Route path="/sneakpeak" element={<SneakPeak />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
